<template>
  <div style="overflow: hidden">
    <div class="logo">
      <img src="~@/assets/szlogo2.png" alt="" style="height:50px" />
    </div>
    <div class="user">
      <!-- <a-avatar :size="54" icon="user" /> -->
      <div class="username">
        {{ userInfo.realName }}
        <a
          href="javascript:;"
          @click="logout"
          style="color: #fff; font-size: 14px;margin-left: 20px;"
          >退出</a
        >
      </div>

      <div class="dept">
        <router-link to="/inputs_infoCenter" style="margin-right:20px;color: #fff; font-size: 14px">
          <img src="@/assets/infoIcon.png" alt=""> <span>消息</span>
        </router-link>
      </div>
    </div>
    <div class="nav" style="float:none">
      <div class="navList" style="text-align:center">
        <span v-for="(item,index) in userInfo.menuList" :key="index" >
          <router-link :to="item.url" :target="item.target=='menuItem'?'_self':'_blank'">{{item.menuName}}</router-link>
        </span>
        <span v-if="roleName.indexOf('组长')>-1||roleName.indexOf('领导')>-1"><router-link :to="{path:'/inputs_teamAllocation'}" active-class="router-link-active" exact>工作分配</router-link></span>
        <span v-if="roleName.indexOf('组员')>-1"><router-link :to="{path:'/inputs_itemAllocation'}" active-class="router-link-active" exact>我的任务</router-link></span>
        
      </div>
    </div>
  </div>
</template>

<script>
import { delCookie, delSessionItem,getCookie } from "../../../until/tools";
export default {
  data(){
    return{
      path:'',
      roleName:getCookie('roleName')
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    },
  },
  methods: {
    logout() {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "您确定要退出吗？",
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          // setCookie('loginTargetUrl',that.$route.path,{time:1});
          delCookie("bd_sid");
          delCookie("canInput");
          delSessionItem("dims");
          that.$router.push("/login");
        },
      });
    },
    toList() {
      //获取用户信息后鉴权进入对应页面
      //0组员 1领导 2组长
      this.$http.getUserInfo().then((result) => {
        let userType=''
          if (result.success) {
              userType=result.userInfo.userType
          }
          if(userType==1){
            this.path="/inputs_allocation"
          }else if(userType==2){
            // this.$router.push("/inputs_teamAllocation")
            this.path="/inputs_teamAllocation"
          }else{
            // this.$router.push("/inputs_itemAllocation")
            this.path="/inputs_itemAllocation"
          }
      });
    },
  },
  mounted(){
    this.toList()
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1276px) {
   .ant-layout-header .nav .navList span {
      margin: 0 14px;
  }
}
</style>