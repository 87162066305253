<template>
    <a-layout style="height:100%">
			<a-layout-header style="height:50px;">
				<Header />
			</a-layout-header>
			<router-view />
		</a-layout>
</template>

<script>
import Header from './components/Header.vue';
export default {    
	components:{Header},
	created() {
		this.$http.getUserInfo().then(res=>{
			if(res.userInfo) {
				let userInfo = res.userInfo;
				this.$store.commit('setUserInfo',userInfo);
			}
		});
	}
}
</script>